<template>
    <v-app style="background: transparent" :class="{}">
        <template v-if="route_children">
            <router-view></router-view>
        </template>
        <template v-else>
            <!-- begin:: Content Head -->
            <KTSubheader
                    v-if="nav.subheaderDisplay"
                    v-bind:breadcrumbs="nav.breadcrumbs"
                    v-bind:title="nav.pageTitle"
            />
            <!-- end:: Content Head -->

            <div class="d-flex flex-column-fluid">
                <!--begin::Container-->
                <div class="container">

                    <!--begin::Card-->
                    <div class="card card-custom">
                        <div class="card-header flex-wrap border-0 pt-6 pb-0">
                            <div class="card-title">
                                <h3 class="card-label">
                                    供应商列表
                                    <span class="d-block text-muted pt-2 font-size-sm"></span>
                                </h3>
                            </div>
                            <div class="card-toolbar">

                                <!--begin::Button-->
                                <a href="javascript:;" class="btn btn-primary font-weight-bolder" @click="addSupplier()">
                                    新增供应商
                                </a>
                                <!--end::Button-->
                            </div>
                        </div>
                        <div class="card-body">
                            <!--begin: Search Form-->

                            <!--begin::Search Form-->
                            <div class="mb-7">
                                <div class="row align-items-center">
                                    <div class="col-lg-9 col-xl-8">
                                        <div class="row align-items-center">
                                            <div class="col-md-4 my-2 my-md-0">
                                                <div class="input-icon">
                                                    <input type="text" class="form-control" placeholder="供应商名称" v-model="search_form.name">
                                                    <span>
                                                    <i class="flaticon2-search-1 text-muted"></i>
                                                </span>
                                                </div>
                                            </div>
                                            <a href="#" class="btn btn-light-primary px-6 font-weight-bold" v-on:click="searchList()">Search</a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <!--end::Search Form-->
                            <!--end: Search Form-->


                            <KTDatatable
                                    v-bind:list="list"
                                    v-bind:column="column"
                                    v-bind:showSelect="false"
                                    v-bind:showPagination="true"
                                    v-bind:pageSize="page.limit"
                                    v-bind:pageCount="page.pageCount"
                                    v-bind:total="page.total"
                                    @operateHandler="operateHandler"
                                    @pagechangeHandler="pagechangeHandler"
                            ></KTDatatable>

                        </div>
                    </div>
                    <!--end::Card-->
                </div>
                <!--end::Container-->
            </div>

            <v-dialog v-model="dialog_show" persistent max-width="700px">
                <component
                        :key="dialog_id"
                        :title="dialog_title"
                        :visible="dialog_show"
                        :is="dialog_view"
                        :dialog_data="dialog_data"
                        @opeareResultHandler="opeareResultHandler"></component>
            </v-dialog>
        </template>
    </v-app>

</template>
<style lang="css">
    @import "~@/assets/css/common.css";
</style>
<script>
    import KTSubheader from "@/view/layout/subheader/Subheader.vue";
    import KTDatatable from "@/view/content/tables/Datatable.vue";

    import addSupplier from '@/view/pages/supplier/AddSupplier';
    import updateSupplier from '@/view/pages/supplier/UpdateSupplier';

    import KTUtil from "@/assets/js/components/util";
    import {getSupplierList, deleteSupplier} from '@/api/supplier';

    export default {
        name: "supplier_view",
        components: {
            KTSubheader,
            KTDatatable
        },
        created: function(){
            var _this = this;

            //判断路由
            if('/' + this.$route.name == this.$route.path){
                _this.searchList();
            } else {
                _this.route_children = true;
            }


        },
        data() {
            return {
                route_children: false,
                nav: {
                    subheaderDisplay: true,
                    breadcrumbs : [{'id':'', 'route':'/supplier', 'title':'供应商管理'}],
                    pageTitle: '仓库'
                },
                list: [],
                column: [{
                    field: 'name',
                    title: '名称',
                },{
                    field: 'email_skype',
                    title: 'Email/Skype',
                },{
                    field: 'phone',
                    title: '联系电话'
                },{
                    field: 'supplier_type',
                    title: '类型',
                    formatter: function(row) {
                        if(row.supplier_type == 1){
                            return '供应商'
                        } else if(row.supplier_type == 2) {
                            return '广告推广商'
                        }
                    }
                },{
                    field: 'payee',
                    title: '收款人',
                },{
                    field: 'channel',
                    title: '收款方式',
                },{
                    field: 'account',
                    title: '收款账号',
                },{
                    field: 'create_time',
                    title: '创建时间',
                    width: 150,
                    formatter: function(row) {
                        return KTUtil.timestamp2date(row.create_time);
                    }
                },{
                    field: 'operate',
                    title: '操作',
                    width: 150,
                    formatter: function(row){
                        let html = '' +
                            '<a href="javascript:;" class="btn btn-sm btn-clean btn-icon mr-2" title="修改">'+
                            '<i class="la la-edit icon-lg"></i>'+
                            '</a>'+
                            '<a href="javascript:;" class="btn btn-sm btn-clean btn-icon mr-2" title="删除">'+
                            '<i class="la la-trash-o icon-lg"></i>'+
                            '</a>';
                        return html;
                    }
                }],
                search_form: {
                    name: ''
                },
                page: {
                    currPage: 1,
                    limit: 15,
                    pageCount:0,
                    total:0,
                },
                dialog_id: 0,
                dialog_show: false,
                dialog_title: '',
                dialog_view: null,
                dialog_data: null
            }
        },
        watch: {
            $route: {
                handler(val, oldval) {
                    if('/' + this.$route.name == this.$route.path){
                        this.route_children = false;
                    } else {
                        this.route_children = true;
                    }
                },
                deep: true
            }
        },
        methods: {
            searchList() {
                var _this = this;
                var data = _this.search_form;
                data['page'] = _this.page.currPage;
                data['limit'] = _this.page.limit;
                getSupplierList(data).then(function(res){
                    if(res){
                        _this.list = res.response.list;
                        _this.page.total = res.response.total;
                        _this.page.pageCount = res.response.count;
                    }
                });
            },
            operateHandler(clazz, row) {
                var _this = this;
                if(clazz.indexOf('la-edit') > -1){
                    //编辑操作
                    this.dialog_id = new Date().getTime();
                    this.dialog_show = true;
                    this.dialog_title = '编辑供货商';
                    this.dialog_view = updateSupplier;
                    this.dialog_data = {
                        form_data: row
                    };
                } else if(clazz.indexOf('la-trash-o') > -1){

                    //删除操作
                    KTUtil.confirm('操作提示', '是否确认删除该条记录？', function(){
                        deleteSupplier({id: row.id}).then(function (res) {
                            if (res) {
                                KTUtil.toast(_this, '结果提醒', '删除成功', 'success');
                                _this.searchList();
                            }
                        });
                    });
                }
            },
            pagechangeHandler(page, pageSize) {
                this.page.currPage = page;
                this.page.limit = pageSize;
                this.searchList();
            },
            addSupplier() {
                this.dialog_id = new Date().getTime();
                this.dialog_show = true;
                this.dialog_title = '新增供应商';
                this.dialog_view = addSupplier;
                this.dialog_data = {

                };
            },
            opeareResultHandler(result) {
                if(result){
                    this.searchList();
                }
                this.dialog_show = false;
            }
        }
    }
</script>
