<template>
    <div class="card card-custom example example-compact">
        <div class="card-header">
            <h3 class="card-title">{{title}}</h3>
            <button type="button" class="close" @click="cancelForm()">
                <i aria-hidden="true" class="ki ki-close"></i>
            </button>
        </div>

        <!--begin::Form-->
        <form class="form fv-plugins-bootstrap fv-plugins-framework" ref="dataForm" id="dataForm">
            <div class="card-body" style="max-height:500px;overflow:auto;">
                <div class="form-group row">
                    <label class="col-lg-3 col-form-label">供应商名称 <span class="text-danger">*</span>:</label>
                    <div class="col-lg-6">
                        <input type="text" class="form-control" placeholder="请输入供应商名称" v-model="data_form.name" name="name">
                        <span class="form-text text-muted"></span>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-lg-3 col-form-label">供应类型 <span class="text-danger">*</span>:</label>
                    <div class="col-lg-6">
                        <select class="form-control" v-model="data_form.supplier_type" name="supplier_type">
                            <option value="1">供应商</option>
                            <option value="2">广告推广商</option>
                        </select>
                        <span class="form-text text-muted"></span>
                    </div>
                </div>


                <div class="form-group row">
                    <label class="col-lg-3 col-form-label">联系电话:</label>
                    <div class="col-lg-6">
                        <input type="text" class="form-control" placeholder="请输入供应商联系电话" v-model="data_form.phone" name="phone">
                        <span class="form-text text-muted"></span>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-lg-3 col-form-label">Email/Skype:</label>
                    <div class="col-lg-6">
                        <input type="text" class="form-control" placeholder="请输入Email/Skype" v-model="data_form.email_skype" name="email_skype">
                        <span class="form-text text-muted"></span>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-lg-3 col-form-label">收款人:</label>
                    <div class="col-lg-6">
                        <input type="text" class="form-control" placeholder="请输入收款人" v-model="data_form.payee" name="payee">
                        <span class="form-text text-muted"></span>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-lg-3 col-form-label">支付方式:</label>
                    <div class="col-lg-6">
                        <input type="text" class="form-control" placeholder="请输入支付方式" v-model="data_form.channel" name="channel">
                        <span class="form-text text-muted"></span>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-lg-3 col-form-label">支付账号:</label>
                    <div class="col-lg-6">
                        <input type="text" class="form-control" placeholder="请输入支付账号" v-model="data_form.account" name="account">
                        <span class="form-text text-muted"></span>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-lg-3 col-form-label">描述:</label>
                    <div class="col-lg-6">
                        <textarea class="form-control" placeholder="请输入供应商说明" v-model="data_form.note" name="note"></textarea>
                        <span class="form-text text-muted"></span>
                    </div>
                </div>
            </div>

            <div class="card-footer">
                <button ref="data_form_submit" class="btn btn-primary mr-2">保存</button>
                <button class="btn btn-secondary" type="reset" @click="cancelForm()">关闭</button>
            </div>
        </form>
        <!--end::Form-->
    </div>
</template>
<script>

    import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
    import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
    import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
    import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
    import KTUtil from "@/assets/js/components/util";

    import {addSupplier} from '@/api/supplier';

    export default {
        name: "supplier_add",
        props: {
            title: String,
            dialog_data: Object
        },
        created:function() {

        },
        components :{

        },
        data() {
            return {
                data_form:{
                    name: '',
                    note: '',
                    email_skype: '',
                    phone: '',
                    supplier_type: '',
                    payee: '',
                    channel: '',
                    account: ''
                },
            }
        },
        mounted() {
            const data_form = KTUtil.getById("dataForm");

            //表单数据验证
            this.fv = formValidation(data_form, {
                fields: {
                    name: {
                        validators: {
                            notEmpty: {
                                message: "供应商名称不能为空"
                            }
                        }
                    },
                    supplier_type: {
                        validators: {
                            notEmpty: {
                                message: "请选择供应商类型"
                            }
                        }
                    }
                },
                plugins: {
                    trigger: new Trigger(),
                    submitButton: new SubmitButton(),
                    bootstrap: new Bootstrap()
                }
            });
            this.fv.on("core.form.valid", () => {
                var _this = this;

                //按钮加载动画
                const submitButton = this.$refs["data_form_submit"];
                submitButton.classList.add("spinner", "spinner-light", "spinner-right");

                addSupplier(_this.data_form).then(function (res){
                    if (res) {
                        KTUtil.toast(_this, '结果提醒', '保存成功', 'success');
                        data_form.reset();
                        _this.$emit('opeareResultHandler', 1);
                    }
                    submitButton.classList.remove(
                        "spinner",
                        "spinner-light",
                        "spinner-right"
                    );
                });
            });
        },
        methods: {
            cancelForm(){
                this.$emit('opeareResultHandler', 0);

            },
            regionChange (data) {
                this.data_form.struct_address = data;
            }
        }
    }
</script>
