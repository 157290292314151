import request from "./request";

export function addSupplier(params){
    return request({
        url: 'supplier/addSupplier',
        method: 'post',
        data: params
    });
}

export function getSupplierList(params){
    return request({
        url: 'supplier/getSupplierList',
        method: 'post',
        data: params
    });
}

export function updateSupplier(params){
    return request({
        url: 'supplier/updateSupplier',
        method: 'post',
        data: params
    });
}

export function deleteSupplier(params){
    return request({
        url: 'supplier/deleteSupplier',
        method: 'post',
        data: params
    });
}
